<template>
    <div class="equity w-1400">
        <!--不动产信息查询-->
        <div class="tr-search">
            <div class="eq-h3 clearfix">
                <div class="fl">
                    <i class="arrow-r"></i>
                    交易信息统计
                </div>
                <div class="search-box fl">
                    <!-- <el-input v-model="searchKey" placeholder="请输入产权证号" style="width:300px"></el-input>
                    <el-button type="primary">查询</el-button> -->
                </div>
                <!-- <div class="fr more">
                    更多<i class="el-icon-arrow-right"></i>
                </div> -->
            </div>
            <div class="re-mian mt-20">
                <el-row :gutter="30">
                    <el-col :span="12" style="position:relative">
                        <div id="re-chart1"></div>
                        <div class="isUpdate">更新中 ...</div>
                    </el-col>
                    <el-col :span="12" style="position:relative">
                        <div id="re-chart2"></div>
                        <div class="isUpdate">更新中 ...</div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--成交信息-->
        <div class="turnover w-1400">
            <div class="eq-h3 clearfix">
                <div class="fl">
                    <i class="arrow-r"></i>
                    成交信息
                </div>
                <!-- <div class="fr more">
                    更多<i class="el-icon-arrow-right"></i>
                </div> -->
            </div>
            <div class="tn-main">
                <el-row :gutter="30">
                    <el-col :span="12" v-for="item in pageData.list" :key="item.id">
                        <div class="tn-item clearfix" v-if="item.RentInfoIsDeleted==true" :class="{act:item===1}">
                            <router-link :to="`/propertydetail?id=${item.rentInfoId}&rt=${item.rentDate&&item.rentDate.substr(0,10)}&cycle=${item.rentCycle}&way=${item.rentChannel}&mode=${item.rentMode}`">
                                <div class="fl tn-pic" v-if="item.photoUrls">
                                    <el-image :src="baseUrl+item.photoUrls.split(',')[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                    <div class="tn-mark">已成交</div>
                                </div>
                                <div class="fr tn-info">
                                    <p class="ellipsis">{{item.rentInfoName}}</p>
                                    <p class="ellipsis">成交价格：{{item.rentPrice}}</p>
                                    <p class="ellipsis">成交周期：{{item.rentCycle}}天</p>
                                    <p class="ellipsis">成交渠道：{{item.rentChannel}}</p>
                                    <p class="ellipsis">成交时间：{{item.rentDate&&item.rentDate.substr(0,10)}}</p>
                                </div>
                            </router-link>
                        </div>
                        <div class="tn-item clearfix" v-else :class="{act:item===1}">
                            <!-- 出租信息（父表信息）已删除的情况/IsDeleted=0 -->
                                <div class="fl tn-pic" v-if="item.photoUrls">
                                    <el-image :src="baseUrl+item.photoUrls.split(',')[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                    <div class="tn-mark">已成交</div>
                                </div>
                                <div class="fr tn-info">
                                    <p class="ellipsis">{{item.rentInfoName}}（已下线）</p>
                                    <p class="ellipsis">成交价格：{{item.rentPrice}}</p>
                                    <p class="ellipsis">成交周期：{{item.rentCycle}}天</p>
                                    <p class="ellipsis">成交渠道：{{item.rentChannel}}</p>
                                    <p class="ellipsis">成交时间：{{item.rentDate&&item.rentDate.substr(0,10)}}</p>
                                </div>
                            <!--  -->
                        </div>
                    </el-col>
                </el-row>
                <el-pagination class="pagination" style="margin-top:20px" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>                    
            </div>
        </div>
    </div>
</template>
<script>
import {GetRentdoneV2,GetRentDoneFw,GetRentDoneTd} from '@/api/ziyuanjishi'
export default {
    data(){
        return{
            pageParams:{
                currentPage: 1,
                filter: {title: "",regionCode:0},
                pageSize: 10,
            },
            pageData:{},
            rentFw:[],  //房屋交易数据
            rentTd:[],  //土地交易数据
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
    },
    mounted(){
        this.getPageList();
        this.getRentFw();
        this.getRentTd()
    },
    methods:{
        drowEchart1(){
            let option ={
                title:{
                    text:'房屋交易趋势统计',
                    top:10
                },
                legend:{
                    data:this.rentFw.map(item=>item.subType),
                    top:10,
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: this.rentFw[0]&&this.rentFw[0].dataList.map(item=>item.yearMonth)
                },
                yAxis: {
                    type: 'value'
                },
                series: 
                    this.rentFw.map(item=>{
                        return{
                            data: item.dataList.map(v=>v.number),
                            type: 'line',
                            name: item.subType,
                            smooth: true,
                        }
                    })
            };
            let mychart = this.echart.init(document.getElementById('re-chart1'));
            mychart.setOption(option)
        },
        drowEchart2(){
            let option ={
                title:{
                    text:'土地交易趋势统计',
                    top:10
                },
                legend:{
                    data:this.rentTd.map(item=>item.subType),
                    top:10,
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: this.rentTd[0]&&this.rentTd[0].dataList.map(item=>item.yearMonth)
                },
                yAxis: {
                    type: 'value'
                },
                series: 
                    this.rentTd.map(item=>{
                        return{
                            data: item.dataList.map(v=>v.number),
                            type: 'line',
                            name: item.subType,
                            smooth: true,
                        }
                    })
            };
            let mychart = this.echart.init(document.getElementById('re-chart2'));
            mychart.setOption(option)
        },
        getRentFw(){
            GetRentDoneFw().then(res=>{
                this.rentFw = res.data.data;
                this.drowEchart1()
            })
        },
        getRentTd(){
            GetRentDoneTd().then(res=>{
                this.rentTd = res.data.data;
                this.drowEchart2();
            })
        },
        getPageList(){
            // this.pageParams
            GetRentdoneV2(this.pageParams).then(res=>{
                this.pageData = res.data.data;
            })
        },
        //分页
        handleCurrentChange(page) {
            this.pageParams.currentPage = page;
            this.getPageList();
        },
    }
}
</script>
<style scoped>
#re-chart1,#re-chart2{
    height: 360px;
    width: 100%;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
}
.isUpdate{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 360px;
    background: rgba(255, 255, 255, .8);
    border: 1px solid #ddd;
    font-size: 20px;
    color: #E6A23C;
}
.search-box >>> .el-button{
    border-radius: 0;
}
.search-box >>> .el-button--primary {
    background-color: #0169ca;
    border-color: #0169ca;
}
.search-box >>> .el-input__inner{
    border-color: #0169ca;
    border-radius: 0;
}
.search-box{
    margin-left: 100px;
}
.tn-info{
    width: calc(100% - 290px);
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
}
.tn-mark{
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 20px;
    background: #3aceda;
    font-size: 20px;
    color: #fff;
}
.tn-pic{
    position: relative;
    width: 270px;
    height: 250px;
}
.tn-item:hover a,.tn-item.act a{
    color: #fff
}
.tn-item:hover,.tn-item.act{
    background: #0e6ac1;
}
.tn-item{
    padding: 16px;
    background: #ddd;
    border-radius: 3px;
    margin-top: 20px;
}
.eq-h3 .arrow-r{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right2.png) no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
}
.eq-h3{
    height: 42px;
    line-height: 42px;
    font-size: 20px;

}
.equity{
    font-family: '微软雅黑';
}
</style>